@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  -moz-osx-font-smoothing: grayscale;
  background-image: url(assets/img/01_start/space-background.png);
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -999;
 /* background: rgb(20, 12, 136);*/
  /*background: linear-gradient(180deg, rgba(10,154,200,1) 0%, rgba(41,174,191,1) 30%, rgba(91,207,177,1) 55%, rgba(132,233,165,1) 80%, rgba(158,250,157,1) 100%);*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*
body css:
 background-image: url("./assets/img/01_start/start_bg-blurry-01.png");
  background-repeat: no-repeat;
  background-size: 120% 120%;
  background-position: center;
  background-color: rgba(0,0,0,0.3);
 ----

linear-gradient(180deg, rgba(7,152,201,1) 0%, rgba(96,210,175,1) 50%, rgba(153,247,158,1) 77%, rgba(162,253,156,1) 100%)
linear-gradient(180deg, rgba(7,152,201,1) 0%, rgba(96,210,175,1) 42%, rgba(162,253,156,1) 100%)
 */
.gradientBackground{
  background: rgb(10,154,200);
  background: linear-gradient(180deg, rgba(10,154,200,1) 0%, rgba(41,174,191,1) 30%, rgba(91,207,177,1) 55%, rgba(132,233,165,1) 80%, rgba(158,250,157,1) 100%);
}


.face_center{
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

.alien-center, .item-center{
  position: absolute;
  width: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  -webkit-touch-callout: none !important;
  -webkit-user-select: none !important;
  -khtml-user-select: none !important;
  -moz-user-select: none !important;
  -o-user-select: none !important;
  user-select: none !important;
}

img:hover{
  background: none;
}

img:active{
  background: none;
}
