.heart{
    position: absolute;
    top: -30%;
    width: 50px;
    height: 50px;
    fill: red;
}
.smile-face{
    position: absolute;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.face_center{
    position: absolute;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}
